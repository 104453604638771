import React, { useRef } from 'react';
import './Customers.css';

const Customers: React.FC = () => {
  const photos = [
    {
      id: 1,
      photo: '/assets/customer-1.jpg',
      alt: 'Customer 1',
    },

    {
      id: 2,
      photo: '/assets/customer-2.jpg',
      alt: 'Customer 2',
    },

    {
      id: 3,
      photo: '/assets/customer-3.jpg',
      alt: 'Customer 3',
    },
  ];

  const sliderRef = useRef<HTMLDivElement>(null);
  const scrollLenght = 240;

  const next = () => {
    if (sliderRef.current) sliderRef.current.scrollTo({ left: sliderRef.current?.scrollLeft + scrollLenght });
  };

  const prev = () => {
    if (sliderRef.current) sliderRef.current.scrollTo({ left: sliderRef.current?.scrollLeft - scrollLenght });
  };

  return (
    <div className='customers'>
      <div className='container'>
        <div className='customers-content'>
          <div>
            <h2>Let the results speaks.</h2>

            <p>
              Vestibulum eu quam nec neque pellentesque efficitur id eget nisl. Proin porta est convallis lacus blandit
              pretium sed non enim. Maecenas lacinia non orci at aliquam.
            </p>
          </div>
        </div>
        <div className='photos-container'>
          <div className='pn-btn prev-btn' onClick={prev}>
            <img src='/assets/arrow-right.svg' alt='Arrow Left' />
          </div>

          <div className='slider' ref={sliderRef}>
            {photos.map(item => (
              <div key={item.id} className='photo-wrapper'>
                <img src={item.photo} alt={item.alt} />
              </div>
            ))}
          </div>

          <div className='pn-btn next-btn' onClick={next}>
            <img src='/assets/arrow-right.svg' alt='Arrow Right' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
