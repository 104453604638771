import React from 'react';
import './App.css';
import Hero from '../Hero/Hero';
import MoreThan from '../MoreThan/MoreThan';
import Stylists from '../Stylists/Stylists';
import Customers from '../Customers/Customers';
import Map from '../Map/Map';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';

const App: React.FC = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <MoreThan />
      <Stylists />
      <Customers />
      <Map />
      <Footer />
    </>
  );
};

export default App;
