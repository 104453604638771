import React from 'react';
import './Hero.css';

const Hero: React.FC = () => {
  return (
    <div className='hero'>
      <div className='container'>
        <div className='hero-content'>
          <h1>The Best Barber Experience You'll Ever Have</h1>
          <div className='sub-title'>We are the hair culture.</div>

          <div className='btn-group'>
            <div className='btn btn-primary'>Book Service</div>
            <div className='btn btn-ghost'>Buy Pomade</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
