import React, { useRef, useState } from 'react';
import './Navbar.css';

const Navbar: React.FC = () => {
  const navbarRef = React.useRef<HTMLDivElement>(null);

  window.addEventListener('scroll', () => {
    if (window.pageYOffset > 100) {
      navbarRef.current?.classList.add('nav-scrolled');
      navMobileRef.current?.classList.add('nav-scrolled');
    } else {
      navbarRef.current?.classList.remove('nav-scrolled');
      navMobileRef.current?.classList.remove('nav-scrolled');
    }
  });

  const NavBrand = () => <div className='nav-brand'>Billionaire Habits</div>;

  const [showDropDown, setShowDropDown] = useState(false);

  const linkItems = [
    { id: 1, className: 'nav-link', text: 'Stylists' },
    { id: 2, className: 'nav-link', text: 'Customers' },
    { id: 3, className: 'nav-link', text: 'Direction' },
    { id: 4, className: 'btn btn-big btn-trans', text: 'Book Service' },
  ];

  const transRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const closeDropdown = () => {
    transRef.current?.classList.add('fade-out');
    dropdownRef.current?.classList.add('slide-out-top');
    setTimeout(() => setShowDropDown(false), 1000);
  };

  const navMobileRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className='navbar' ref={navbarRef}>
        <div className='container'>
          <NavBrand />

          <div className='link-container'>
            {linkItems.map(item => (
              <div className={item.className} key={item.id}>
                {item.text}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='nav-mobile' ref={navMobileRef}>
        <NavBrand />
        {showDropDown ? <div className='trans fade-in' onClick={closeDropdown} ref={transRef}></div> : null}
        <img src='/assets/menu-outline.svg' alt='Menu' className='menu-button' onClick={() => setShowDropDown(true)} />

        {showDropDown ? (
          <div className='dropdown-menu slide-in-top' ref={dropdownRef}>
            {linkItems.map(item => (
              <div key={item.id} className={item.className}>
                {item.text}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Navbar;
