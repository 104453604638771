import React from 'react';
import './Map.css';

const Map: React.FC = () => {
  return (
    <div className='map-section'>
      <div className='container'>
        <div className='contactDetails'>
          <div>
            <div className='oneDetail'>
              <h3>Visit Us</h3>

              <p>
                <a href='https://goo.gl/maps/5QVb6xDPWVcYL1N99' target='_blank' rel='noopener noreferrer'>
                  Jalan Letjen S. Parman No. 280 Medan, Sumatera Utara.
                </a>
              </p>
            </div>

            <div className='oneDetail'>
              <h3>Contact Us</h3>

              <p>
                <a href='mailto:hello@billionairehabits.com' target='_blank' rel='noopener noreferrer'>
                  hello@billionairehabits.com
                </a>

                <br />

                <a href='https://wa.me/6285215996223' target='_blank' rel='noopener noreferrer'>
                  +62 852 1599 6223
                </a>
              </p>
            </div>

            <div className='oneDetail'>
              <h3>Working Hours</h3>
              <p>Everyday 10AM - 10PM</p>
            </div>
          </div>
        </div>

        <div className='map-container'>
          <div id='map'></div>
        </div>
      </div>
    </div>
  );
};

export default Map;
