import React from 'react';
import './MoreThan.css';

const MoreThan: React.FC = () => {
  return (
    <div className='more-than'>
      <div className='container'>
        <div className='square'></div>

        <div className='rest'>
          <h2>More than just another barbershop.</h2>

          <p>
            Vestibulum eu quam nec neque pellentesque efficitur id eget nisl. Proin porta est convallis lacus blandit
            pretium sed non enim. Maecenas lacinia non orci at aliquam.
          </p>

          <p>
            Donec finibus, urna bibendum ultricies laoreet, augue eros luctus sapien, ut euismod leo tortor ac enim. In
            hac habitasse platea dictumst.
          </p>

          <p>
            Sed cursus venenatis tellus, non lobortis diam volutpat sit amet. Sed tellus augue, hendrerit eu rutrum in,
            porttitor at metus. Mauris ac hendrerit metus.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MoreThan;
