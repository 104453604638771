import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='container'>
        <div className='copyright'>© 2020 Billionaire Habits. All rights reserved.</div>

        <div>
          <a href='http://instagram.com/billionairehabits.id/' target='_blank' rel='noopener noreferrer'>
            <img src='/assets/instagram-icon.svg' alt='Instagram' />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
